<script>
import PageHeader from "@/components/page-header";
import skeletonLoading from "@/components/skeleton";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import Layout from "../views/layouts/main.vue";
import { convertDate, convertPrice } from "@/_helpers/index";

export default {
  components: { PageHeader, skeletonLoading, DatePicker, Layout },
  props: {
    formUrl: {
      type: String,
      required: true,
    },
    dataUrl: {
      type: String,
      required: true,
    },
    filterWith: {
      type: String,
      default: "name",
      required: false,
    },
    titleFilter: {
      type: String,
      default: "Filter Berdasarkan Nama",
    },
    withFilterText: {
      type: Boolean,
      default: false,
    },
    withFilterColor: {
      type: Boolean,
      default: false,
    },
    withFilterDate: {
      type: Boolean,
      default: false,
    },
    withFilterStatus: {
      type: Boolean,
      required: false,
    },
    withFilterSize: {
      type: Boolean,
      default: false,
    },
    withFilterCreated: {
      type: Boolean,
      default: false,
    },
    withFilterOrder: {
      type: Boolean,
      default: false,
    },
    withFilterCategory: {
      type: Boolean,
      default: false,
    },
    withFilterRole: {
      type: Boolean,
      required: false,
    },
    skeletonHeight: {
      type: String,
      default: "250px",
    },
    skeletonWidth: {
      type: String,
      default: "100%",
    },
    skeletonRadius: {
      type: String,
      default: "0px",
    },
    selectOptions: [Object, Array],
    paramsCategory: {
      type: String,
      default: "customer_category",
    },
    cardOrder: {
      type: Boolean,
      default: false,
    },
    cardPurchase: {
      type: Boolean,
      default: false,
    },
    cardProduk: {
      type: Boolean,
      default: false,
    },
    cardDefault: {
      type: Boolean,
      default: true,
    },
    cardCategory: {
      type: Boolean,
      default: false,
    },
    cardSize: {
      type: Boolean,
      default: false,
    },
    cardTransaction: {
      type: Boolean,
      default: false,
    },
    cardCustomer: {
      type: Boolean,
      default: false,
    },
    cardColor: {
      type: Boolean,
      default: false,
    },
    cardUser: {
      type: Boolean,
      default: false,
    },
    cardFavorite: {
      type: Boolean,
      default: false,
    },
    cardLoad: {
      type: Number,
      default: 4,
    },
    withIcon: {
      type: String,
      default: "bx bxs-t-shirt",
    },
    withAddData: {
      type: Boolean,
      default: true,
    },
    withActionButton: {
      type: Boolean,
      default: false,
    },
    withDelete: {
      type: Boolean,
      default: false,
    },
    withEdit: {
      type: Boolean,
      default: false,
    },
    withDetail: {
      type: Boolean,
      default: false,
    },
    withRowCategory: {
      type: Boolean,
      default: true,
    },
    withCurrentPage: {
      type: Number,
      default: 4,
    },
  },
  data() {
    return {
      isLoading: false,
      dataApi: [],
      busy: false,
      currentPage: this.withCurrentPage,
      perPage: 1,
      reachedEnd: false,
      openCollapseId: null,
      isModalVisible: false,
      filterText: "",
      filterColor: "",
      filterSize: "",
      filterCreated: "",
      filterRole: "",
      filterStatus: "",
      filterCategory: "",
      filterOrder: "",
      startDate: null,
      filterDate: null,
      endDate: null,
      loadCard: this.cardLoad,
      swipeLeft: false,
      swipeTrigger: null,
    };
  },
  watch: {
    handler() {
      this.clearFilter();
    },
  },
  methods: {
    getLastPathUrl() {
      const fullPath = this.$route.path;
      return fullPath;
    },
    handleSwipeLeft(id) {
      this.swipeLeft = id;
    },
    handleSwipeRight() {
      this.swipeLeft = false;
    },
    formattedPrice(value) {
      return convertPrice(value);
    },
    formattedDate(value) {
      return convertDate(value);
    },
    capitalizeFirstLetter(text) {
      return text.charAt(0).toUpperCase() + text.slice(1);
    },
    async getdataApi(url) {
      if (this.busy || this.reachedEnd) return;
      this.busy = true;
      try {
        const response = await this.$http.get(url ? url : this.dataUrl, {
          params: {
            per_page: 25,
            limit: this.currentPage,
            page: this.perPage,
          },
        });
        const responseData = response.data.data;
        if (responseData.length === 0) {
          this.reachedEnd = true;
        } else {
          this.dataApi = [...this.dataApi, ...responseData];
          if (
            this.perPage <=
            Math.ceil(response.data.meta.total / this.currentPage)
          ) {
            this.perPage++;
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.busy = false;
      }
    },
    constructUrl() {
      return `${this.dataUrl}?`;
    },
    constructUrlFilter() {
      let url = `${this.dataUrl}?`;

      if (this.filterText) {
        if (this.filterText.includes("@")) {
          url += `&email=${this.filterText}`;
        } else if (isNaN(this.filterText)) {
          url += `&${this.filterWith}=${this.filterText}`;
        } else {
          url += `&phone_number=${this.filterText}`;
        }
      }

      if (this.startDate && this.endDate) {
        const formattedStartDate = this.startDate.toISOString().split("T")[0];
        const formattedEndDate = this.endDate.toISOString().split("T")[0];

        url += `&start_date=${formattedStartDate}&end_date=${formattedEndDate}`;
      }

      if (this.filterRole) {
        url += `&role=${this.filterRole}`;
      }

      if (this.filterColor) {
        url += `&cloth_color=${this.filterColor}`;
      }

      if (this.filterCategory) {
        url += `&${this.paramsCategory}=${this.filterCategory}`;
      }

      if (this.filterSize) {
        url += `&cloth_size=${this.filterSize}`;
      }

      if (this.filterStatus) {
        url += `&status=${this.filterStatus}`;
      }

      if (this.filterOrder) {
        url += `&order_number=${this.filterOrder}`;
      }

      return url;
    },
    applyFilter() {
      this.isLoading = true;
      this.dataApi = [];
      this.currentPage = 25;
      this.perPage = 1;
      this.loadCard = 3;
      this.reachedEnd = false;
      const url = this.constructUrlFilter();
      this.getdataApi(url);
      this.isLoading = false;
    },
    clearFilter() {
      this.isLoading = true;
      this.hideModal();
      this.swipeLeft = false;
      this.filterText = "";
      this.filterColor = "";
      this.filterSize = "";
      this.filterRole = "";
      this.filterCreated = "";
      this.filterDate = null;
      this.startDate = null;
      this.endDate = null;
      this.filterStatus = "";
      this.filterOrder = "";
      this.dataApi = [];
      this.currentPage = 4;
      this.perPage = 1;
      this.loadCard = 3;
      this.reachedEnd = false;
      const url = this.constructUrl();
      this.getdataApi(url);
      this.isLoading = false;
    },
    updateFilterDate(value) {
      if (Array.isArray(value) && value.length === 2) {
        this.startDate = value[0];
        this.endDate = value[1];
      } else {
        this.startDate = null;
        this.endDate = null;
      }
    },
    onScreenResize() {
      this.screenWidth = detectScreen();
    },
    formattedPrice(value) {
      return convertPrice(value);
    },
    showModal() {
      this.isModalVisible = true;
      document.body.classList.add("modal-open");
    },
    hideModal() {
      this.isModalVisible = false;
      document.body.classList.remove("modal-open");
    },
    linkAddData() {
      this.$router.push(this.formUrl);
    },
    handleRouteDetail(id) {
      this.$router.push(this.getLastPathUrl() + "/" + id);
    },
    handleRouteEdit(id) {
      this.$router.push(this.getLastPathUrl() + "/" + id + "/edit");
    },
    handleDelete(id) {
      this.$swal
        .fire({
          title: "Apakah Kamu Yakin ?",
          text: "Data tidak dapat dikembalikan!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#34c38f",
          cancelButtonColor: "#f46a6a",
          confirmButtonText: "Ya, Hapus!",
        })
        .then((result) => {
          if (result.value) {
            this.$http
              .delete(`${this.dataUrl + "/" + id}`)
              .then(() => {
                this.$store.dispatch(
                  "notification/success",
                  "Data berhasil dihapus"
                );
                location.reload();
              })
              .catch((error) => {
                this.isLoading = false;
                if (process.env.NODE_ENV !== "production") {
                  console.error(error);
                  if (error.response.status == 404) {
                    this.$router.push("/404-notfound");
                  }
                }
                this.$store.dispatch("notification/error", error);
              });
          }
        });
    },
  },
  mounted() {
    this.getdataApi();
  },
  computed: {
    checkRoles() {
      const getAccount = localStorage.getItem("account_data");
      const parse = JSON.parse(getAccount);
      const cek = parse.role === "owner" || parse.role === "manager";
      return cek ? true : false;
    },
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <b-row>
      <b-col class="col-md-6">
        <PageHeader :title="$route.name" />
      </b-col>
      <b-col class="d-flex justify-content-end col-md-6">
        <span @click="showModal" class="filter"
          ><i class="bx bx-slider-alt"></i> Filter
        </span>
      </b-col>
    </b-row>
    <div
      style="transition: all;"
      v-infinite-scroll="getdataApi"
      infinite-scroll-disabled="busy"
      infinite-scroll-distance="10"
    >
      <b-btn
        v-if="withAddData"
        variant="success"
        @click="linkAddData()"
        class="m-2 text-white fw-bold addData"
        ><i class="uil-plus"></i
      ></b-btn>

      <div
        v-if="cardOrder"
        class="d-flex gap-2 w-100"
        style="width: auto;overflow-x: scroll;height: fit-content;"
        :class="`${swipeLeft === order.id ? 'swipeWrap' : ''}`"
        v-for="order in dataApi"
        :key="order.id"
      >
        <b-card
          v-touch:swipe.left="() => handleSwipeLeft(order.id)"
          v-touch:swipe.right="() => handleSwipeRight(order.id)"
          @click="handleSwipeRight(order.id)"
          class="w-100"
          :class="`${swipeLeft === order.id ? 'swipe' : ''}`"
        >
          <b-row class="overflow-auto" @click="handleRouteDetail(order.id)">
            <b-col
              class="col-3 d-flex justify-content-start"
              style="height: 55px !important;"
            >
              <span
                class="h-100 d-flex justify-content-center align-items-center fs-1 rounded-1"
                style="background-color: #c1c9d7;width: 60px;"
              >
                <i class="bx bxs-purchase-tag"></i>
              </span>
            </b-col>
            <b-col class="d-flex flex-column">
              <span class="fw-bold text-dark fs-5">{{
                order.customer.name
              }}</span>
              <span class="text-secondary fw-bold">{{
                order.customer.customer_category.name
              }}</span>
            </b-col>
            <b-col class="text-end col-3">
              <span class="fs-5 fw-bold text-secondary">{{
                order.total_qty + "pcs"
              }}</span>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col><span class="textSupply fw-bold">Kode Order</span></b-col>
            <b-col class="text-end fw-bold text-secondary"
              ><span>{{ order.order_number }}</span></b-col
            >
          </b-row>
          <b-row class="mt-1">
            <b-col><span class="textSupply fw-bold">Status</span></b-col>
            <b-col class="text-end fw-bold text-secondary"
              ><span>{{ order.status }}</span></b-col
            >
          </b-row>
          <b-row class="mt-1">
            <b-col
              ><span class="textSupply fw-bold">Metode Pembayaran</span></b-col
            >
            <b-col class="text-end fw-bold text-secondary"
              ><span>{{
                capitalizeFirstLetter(order.payment_method)
              }}</span></b-col
            >
          </b-row>
          <b-row class="mt-1">
            <b-col
              ><span class="textSupply fw-bold">Dibuat Tanggal</span></b-col
            >
            <b-col class="text-end fw-bold text-secondary"
              ><span>{{ formattedDate(order.created_at) }}</span></b-col
            >
          </b-row>
          <b-row class="mt-1">
            <b-col><span class="textSupply fw-bold">Total</span></b-col>
            <b-col class="text-end fw-bold text-secondary"
              ><span>{{ formattedPrice(order.total) }}</span></b-col
            >
          </b-row>
        </b-card>
        <b-card
          v-if="swipeLeft === order.id && checkRoles"
          style="width: 150px !important;"
          class="rounded-3 w-100"
          :class="`${swipeLeft === order.id ? 'buttonAction' : ''}`"
        >
          <div
            class="d-flex flex-column justify-content-center align-items-center gap-4 h-100"
          >
            <div>
              <el-button
                @click="handleDelete(order.id)"
                type="danger"
                icon="el-icon-delete"
                circle
                class="btn-custom"
              />
            </div>
          </div>
        </b-card>
      </div>

      <div
        v-if="cardPurchase"
        class="d-flex gap-2 w-100"
        style="width: auto;overflow-x: scroll;height: fit-content;"
        :class="`${swipeLeft === suplier.id ? 'swipeWrap' : ''}`"
        v-for="suplier in dataApi"
        :key="suplier.id"
      >
        <b-card
          v-touch:swipe.left="() => handleSwipeLeft(suplier.id)"
          v-touch:swipe.right="() => handleSwipeRight(suplier.id)"
          @click="handleSwipeRight(suplier.id)"
          class="w-100"
          :class="`${swipeLeft === suplier.id ? 'swipe' : ''}`"
        >
          <b-row class="overflow-auto" @click="handleRouteDetail(suplier.id)">
            <b-col
              class="col-3 d-flex justify-content-start"
              style="height: 55px !important;"
            >
              <span
                class="h-100 d-flex justify-content-center align-items-center fs-1 rounded-1"
                style="background-color: #c1c9d7;width: 60px;"
              >
                <i class="bx bxs-purchase-tag"></i>
              </span>
            </b-col>
            <b-col class="d-flex flex-column">
              <span class="fw-bold text-dark fs-5">{{
                suplier.supplier.name
              }}</span>
              <span class="text-secondary fw-bold">{{ suplier.status }}</span>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col><span class="textSupply fw-bold">NO. Pembelian</span></b-col>
            <b-col class="text-end fw-bold text-secondary"
              ><span>{{ suplier.purchase_number }}</span></b-col
            >
          </b-row>
          <b-row class="mt-1">
            <b-col><span class="textSupply fw-bold">Dibuat Oleh</span></b-col>
            <b-col class="text-end fw-bold text-secondary"
              ><span>{{ suplier.created_by.name }}</span></b-col
            >
          </b-row>
          <b-row class="mt-1">
            <b-col><span class="textSupply fw-bold">Diupdate Oleh</span></b-col>
            <b-col class="text-end fw-bold text-secondary"
              ><span>{{
                suplier.updated_by !== null ? suplier.updated_by : "-"
              }}</span></b-col
            >
          </b-row>
          <b-row class="mt-1">
            <b-col><span class="textSupply fw-bold">Down Payment</span></b-col>
            <b-col class="text-end fw-bold text-secondary"
              ><span>{{
                formattedPrice(suplier.amount_down_payment)
              }}</span></b-col
            >
          </b-row>
          <b-row class="mt-1">
            <b-col><span class="textSupply fw-bold">Total Belanja</span></b-col>
            <b-col class="text-end fw-bold text-secondary"
              ><span>{{ formattedPrice(suplier.grand_total) }}</span></b-col
            >
          </b-row>
          <b-row class="mt-1">
            <b-col><span class="textSupply fw-bold">Sisa Tagihan</span></b-col>
            <b-col class="text-end fw-bold text-secondary"
              ><span>{{
                formattedPrice(
                  suplier.grand_total - suplier.amount_down_payment
                )
              }}</span></b-col
            >
          </b-row>
        </b-card>
        <b-card
          v-if="swipeLeft === suplier.id && checkRoles"
          style="width: 150px !important;"
          class="rounded-3"
          :class="`${swipeLeft === suplier.id ? 'buttonAction' : ''}`"
        >
          <div
            class="d-flex flex-column justify-content-center align-items-center gap-4 h-100"
          >
            <div>
              <el-button
                @click="handleDelete(suplier.id)"
                type="danger"
                icon="el-icon-delete"
                circle
                class="btn-custom"
              />
            </div>
          </div>
        </b-card>
      </div>

      <div
        v-if="cardProduk"
        class="d-flex gap-2"
        style="width: auto;overflow-x: scroll;height: fit-content;"
        :class="`${swipeLeft === produk.id ? 'swipeWrap' : ''}`"
        v-for="produk in dataApi"
        :key="produk.id"
      >
        <b-card
          v-touch:swipe.left="() => handleSwipeLeft(produk.id)"
          v-touch:swipe.right="() => handleSwipeRight(produk.id)"
          @click="handleSwipeRight(produk.id)"
          class="w-100 rounded-3"
          :class="`${swipeLeft === produk.id ? 'swipe' : ''}`"
        >
          <b-row @click="handleRouteDetail(produk.id)">
            <b-col
              class="col-3 d-flex justify-content-start"
              style="height: 55px !important;"
            >
              <span
                class="h-100 d-flex justify-content-center align-items-center fs-1 rounded-1"
                style="background-color: #c1c9d7;width: 60px;"
              >
                <i class="bx bx-closet"></i>
              </span>
            </b-col>
            <b-col class="d-flex flex-column">
              <span class="fw-bold text-dark" style="font-size: 15px;">{{
                produk.cloth_category ? produk.cloth_category.name : "-"
              }}</span>
              <span
                class="badge fw-bold badge-custom mt-2"
                :class="{
                  'bg-success': produk.status === true,
                  'bg-danger': produk.status === false,
                }"
                >{{ produk.status ? "Aktif" : "Tidak Aktif" }}</span
              >
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col><span class="textSupply fw-bold">Dibuat Oleh</span></b-col>
            <b-col class="text-end fw-bold text-secondary"
              ><span>{{
                produk.updated_by && produk.updated_by.name
                  ? produk.updated_by.name
                  : "-"
              }}</span></b-col
            >
          </b-row>
          <b-row class="mt-1">
            <b-col
              ><span class="textSupply fw-bold">Diperbaui Oleh</span></b-col
            >
            <b-col class="text-end fw-bold text-secondary"
              ><span>{{
                produk.created_by && produk.created_by.name
              }}</span></b-col
            >
          </b-row>
          <b-row class="mt-1">
            <b-col
              ><span class="textSupply fw-bold">Dibuat Tanggal</span></b-col
            >
            <b-col class="text-end fw-bold text-secondary"
              ><span>{{ formattedDate(produk.created_at) }}</span></b-col
            >
          </b-row>
          <b-row class="mt-1">
            <b-col
              ><span class="textSupply fw-bold">Diperbaui Tanggal</span></b-col
            >
            <b-col class="text-end fw-bold text-secondary"
              ><span>{{ formattedDate(produk.updated_at) }}</span></b-col
            >
          </b-row>
        </b-card>
        <b-card
          v-if="swipeLeft === produk.id && checkRoles"
          style="width: 150px !important;"
          class="rounded-3"
          :class="`${swipeLeft === produk.id ? 'buttonAction' : ''}`"
        >
          <div
            class="d-flex flex-column justify-content-center align-items-center h-100 gap-2"
          >
            <div>
              <el-button
                @click="handleDelete(produk.id)"
                type="danger"
                icon="el-icon-delete"
                circle
                class="btn-custom"
              />
            </div>
          </div>
        </b-card>
      </div>

      <div
        v-if="cardCategory"
        class="d-flex gap-2"
        style="width: auto;overflow-x: scroll;height: fit-content;"
        :class="`${swipeLeft === items.id ? 'swipeWrap' : ''}`"
        v-for="items in dataApi"
        :key="items.id"
      >
        <b-card
          v-touch:swipe.left="() => handleSwipeLeft(items.id)"
          v-touch:swipe.right="() => handleSwipeRight(items.id)"
          @click="handleSwipeRight(items.id)"
          class="w-100 rounded-3"
          :class="`${swipeLeft === items.id ? 'swipe' : ''}`"
        >
          <b-row>
            <b-col
              @click="handleRouteDetail(items.id)"
              class="col-3 d-flex justify-content-start"
              style="height: 55px !important;"
            >
              <span
                class="h-100 d-flex justify-content-center align-items-center fs-1 rounded-1"
                style="background-color: #c1c9d7;width: 60px;"
              >
                <i :class="withIcon"></i>
              </span>
            </b-col>
            <b-col class="d-flex flex-column">
              <span class="fw-bold text-dark" style="font-size: 15px;">{{
                items.name
              }}</span>
              <span class="fw-bold text-secondary">{{
                items.description ? items.description : "-"
              }}</span>
            </b-col>
          </b-row>
        </b-card>
        <b-card
          v-if="swipeLeft === items.id && checkRoles"
          style="width:250px !important"
          class="rounded-3"
          :class="`${swipeLeft === items.id ? 'buttonAction' : ''}`"
        >
          <div
            class="d-flex flex-row justify-content-center align-items-center gap-2 h-100"
          >
            <div>
              <el-button
                @click="handleRouteEdit(items.id)"
                type="warning"
                icon="el-icon-edit"
                circle
                class="btn-custom"
              />
            </div>
            <div>
              <el-button
                @click="handleDelete(items.id)"
                type="danger"
                icon="el-icon-delete"
                circle
                class="btn-custom"
              />
            </div>
          </div>
        </b-card>
      </div>

      <div
        v-if="cardColor"
        class="d-flex gap-2"
        style="width: auto;overflow-x: scroll;height: fit-content;"
        :class="`${swipeLeft === items.id ? 'swipeWrap' : ''}`"
        v-for="items in dataApi"
        :key="items.id"
        :idCard="items.id"
      >
        <b-card
          v-touch:swipe.left="() => handleSwipeLeft(items.id)"
          v-touch:swipe.right="() => handleSwipeRight(items.id)"
          @click="handleSwipeRight(items.id)"
          class="w-100 rounded-3"
          :class="`${swipeLeft === items.id ? 'swipe' : ''}`"
        >
          <b-row>
            <b-col
              @click="handleRouteDetail(items.id)"
              class="col-3 p-0 d-flex justify-content-center"
              style="height: 55px !important;"
            >
              <div
                class="h-100 w-75 align-items-center rounded-3"
                :style="{ backgroundColor: `#${items.code_hexa}` }"
              ></div>
            </b-col>
            <b-col class="d-flex flex-column">
              <span class="fw-bold text-dark fs-5">{{ items.name }}</span>
              <span class="fw-bold text-secondary">{{
                items.description ? items.description : "-"
              }}</span>
            </b-col>
          </b-row>
        </b-card>
        <b-card
          v-if="swipeLeft === items.id && checkRoles"
          class="rounded-3 h-100"
          style="width: 250px !important;"
          :class="`${swipeLeft === items.id ? 'buttonAction' : ''}`"
        >
          <div
            class="d-flex flex-row justify-content-center align-items-center gap-2"
          >
            <div>
              <el-button
                @click="handleRouteEdit(items.id)"
                type="warning"
                icon="el-icon-edit"
                circle
                class="btn-custom"
              />
            </div>
            <div>
              <el-button
                @click="handleDelete(items.id)"
                type="danger"
                icon="el-icon-delete"
                circle
                class="btn-custom"
              />
            </div>
          </div>
        </b-card>
      </div>

      <div
        v-if="cardSize"
        class="d-flex gap-2"
        style="width: auto;overflow-x: scroll;height: fit-content;"
        :class="`${swipeLeft === items.id ? 'swipeWrap' : ''}`"
        v-for="items in dataApi"
        :key="items.id"
        :idCard="items.id"
      >
        <b-card
          v-touch:swipe.left="() => handleSwipeLeft(items.id)"
          v-touch:swipe.right="() => handleSwipeRight(items.id)"
          @click="handleSwipeRight(items.id)"
          class="w-100 rounded-3"
          :class="`${swipeLeft === items.id ? 'swipe' : ''}`"
        >
          <b-row>
            <b-col
              @click="handleRouteDetail(items.id)"
              class="col-3 d-flex justify-content-start"
              style="height: 55px !important;"
            >
              <span
                class="h-100 d-flex justify-content-center align-items-center fs-1 rounded-1"
                style="background-color: #c1c9d7;width: 60px;"
              >
                <i class="bx bx-ruler"></i>
              </span>
            </b-col>
            <b-col class="d-flex flex-column">
              <span class="fw-bold text-dark fs-5">{{ items.name }}</span>
              <span class="text-secondary">{{
                items.description ? items.description : "-"
              }}</span>
            </b-col>
          </b-row>
        </b-card>
        <b-card
          v-if="swipeLeft === items.id && checkRoles"
          class="rounded-3"
          style="width:250px !important;"
          :class="`${swipeLeft === items.id ? 'buttonAction' : ''}`"
        >
          <div
            class="d-flex flex-row justify-content-center align-items-center gap-2 h-100"
          >
            <div>
              <el-button
                @click="handleRouteEdit(items.id)"
                type="warning"
                icon="el-icon-edit"
                circle
                class="btn-custom"
              />
            </div>
            <div>
              <el-button
                @click="handleDelete(items.id)"
                type="danger"
                icon="el-icon-delete"
                circle
                class="btn-custom"
              />
            </div>
          </div>
        </b-card>
      </div>

      <b-card
        v-if="cardTransaction"
        class="rounded-3 mt-2"
        v-for="items in dataApi"
        :key="items.id"
      >
        <b-row>
          <b-col
            class="col-3 d-flex justify-content-start"
            style="height: 55px !important;"
          >
            <span
              class="h-100 d-flex justify-content-center align-items-center fs-1 rounded-1"
              style="background-color: #c1c9d7;width: 60px;"
            >
              <i class="bx bxs-purchase-tag-alt"></i>
            </span>
          </b-col>
          <b-col class="d-flex flex-column">
            <span class="fw-bold text-dark" style="font-size: 15px;">
              {{
                items.cloth_size
                  ? items.cloth_size.cloth_color
                    ? items.cloth_size.cloth_color.cloth
                      ? items.cloth_size.cloth_color.cloth.cloth_category
                        ? items.cloth_size.cloth_color.cloth.cloth_category.name
                        : "-"
                      : "-"
                    : "-"
                  : "-"
              }}
            </span>
            <span class="fw-bold text-dark">
              {{
                items.cloth_size
                  ? items.cloth_size.cloth_color
                    ? items.cloth_size.cloth_color.color
                      ? items.cloth_size.cloth_color.color.name
                      : "-"
                    : "-"
                  : "-"
              }}
            </span>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col><span class="textSupply fw-bold">Ukuran</span></b-col>
          <b-col class="text-end fw-bold text-secondary">
            <span>
              {{
                items.cloth_size
                  ? items.cloth_size.size
                    ? items.cloth_size.size.name
                    : "-"
                  : "-"
              }}
            </span>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col><span class="textSupply fw-bold">Stok</span></b-col>
          <b-col class="text-end fw-bold text-secondary">
            <span>
              {{ items.cloth_size ? items.cloth_size.stock : "-" }}
            </span>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col><span class="textSupply fw-bold">Stok In</span></b-col>
          <b-col class="text-end fw-bold text-secondary">
            <span>{{ items.stock_in ? items.stock_in : "-" }}</span>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col><span class="textSupply fw-bold">Stok Out</span></b-col>
          <b-col class="text-end fw-bold text-secondary">
            <span>{{ items.stock_out ? items.stock_out : "-" }}</span>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col><span class="textSupply fw-bold">Dibuat Oleh</span></b-col>
          <b-col class="text-end fw-bold text-secondary">
            <span>{{ items.created_by ? items.created_by.name : "-" }}</span>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col><span class="textSupply fw-bold">Tanggal Dibuat</span></b-col>
          <b-col class="text-end fw-bold text-secondary">
            <span>{{
              items.created_at ? formattedDate(items.created_at) : "-"
            }}</span>
          </b-col>
        </b-row>
      </b-card>

      <b-card
        v-if="cardFavorite"
        class="rounded-3 mt-2"
        v-for="(items, index) in dataApi"
        :key="items.id"
      >
        <b-row>
          <b-col
            class="col-3 d-flex justify-content-start"
            style="height: 55px !important;"
          >
            <span
              v-if="[0, 1, 2].includes(index)"
              class="h-100 d-flex justify-content-center align-items-center fs-1 rounded-1"
              style="background-color: #c1c9d7;width: 60px;"
            >
              <i class="bx bxs-bookmark-star"></i>
            </span>
            <span
              v-else
              class="h-100 d-flex justify-content-center align-items-center fs-1 rounded-1"
              style="background-color: #c1c9d7;width: 60px;"
            >
              <i class="bx bxs-bookmark"></i>
            </span>
          </b-col>
          <b-col class="d-flex flex-column">
            <span class="fw-bold text-dark" style="font-size: 15px;">
              {{ items.category_name ? items.category_name : "-" }}
            </span>
            <span class="fw-bold text-dark">
              {{ items.color ? items.color : "-" }}
            </span>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col><span class="textSupply fw-bold">Ukuran</span></b-col>
          <b-col class="text-end fw-bold text-secondary">
            <span>
              {{ items.size ? items.size : "-" }}
            </span>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col><span class="textSupply fw-bold">Total Penjualan</span></b-col>
          <b-col class="text-end fw-bold text-secondary">
            <span>{{ items.total_qty ? items.total_qty : "-" }}</span>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col><span class="textSupply fw-bold">Sub Total</span></b-col>
          <b-col class="text-end fw-bold text-secondary">
            <span>{{
              items.sub_total ? formattedPrice(items.sub_total) : "Rp. 0,-"
            }}</span>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col><span class="textSupply fw-bold">Tanggal Dibuat</span></b-col>
          <b-col class="text-end fw-bold text-secondary">
            <span>{{
              formattedDate(items.created_at ? items.created_at : "-") || "-"
            }}</span>
          </b-col>
        </b-row>
      </b-card>

      <div
        class="d-flex gap-2"
        style="width: auto;overflow-x: scroll;height: fit-content;"
        :class="`${swipeLeft === customer.id ? 'swipeWrap' : ''}`"
        v-if="cardCustomer"
        v-for="customer in dataApi"
        :key="customer.id"
      >
        <b-card
          v-touch:swipe.left="() => handleSwipeLeft(customer.id)"
          v-touch:swipe.right="() => handleSwipeRight(customer.id)"
          @click="handleSwipeRight(customer.id)"
          class="w-100 rounded-3"
          :class="`${swipeLeft === customer.id ? 'swipe' : ''}`"
        >
          <b-row @click="handleRouteDetail(customer.id)">
            <b-col
              class="col-3 d-flex justify-content-start"
              style="height: 55px !important;"
            >
              <span
                class="h-100 d-flex justify-content-center align-items-center fs-1 rounded-1"
                style="background-color: #c1c9d7;width: 60px;"
              >
                <i class="bx bxs-user"></i>
              </span>
            </b-col>
            <b-col class="d-flex flex-column">
              <span class="fw-bold text-dark" style="font-size: 15px;">{{
                customer.name
              }}</span>
              <span class="fw-bold text-secondary">
                {{ customer.email }}
              </span>
            </b-col>
          </b-row>
          <b-row class="mt-2" v-if="withRowCategory">
            <b-col><span class="textSupply fw-bold">Kategori</span></b-col>
            <b-col class="text-end fw-bold text-secondary"
              ><span>{{
                customer.customer_category && customer.customer_category.name
                  ? customer.customer_category.name
                  : "-"
              }}</span></b-col
            >
          </b-row>
          <b-row class="mt-1">
            <b-col><span class="textSupply fw-bold">No. Handphone</span></b-col>
            <b-col class="text-end fw-bold text-secondary"
              ><span>{{
                customer.phone_number && customer.phone_number
                  ? customer.phone_number
                  : "-"
              }}</span></b-col
            >
          </b-row>
          <b-row class="mt-1">
            <b-col><span class="textSupply fw-bold">Provinsi</span></b-col>
            <b-col class="text-end fw-bold text-secondary"
              ><span>{{
                customer.province && customer.province.name
                  ? customer.province.name
                  : "-"
              }}</span></b-col
            >
          </b-row>
          <b-row class="mt-1">
            <b-col><span class="textSupply fw-bold">Kab/Kota</span></b-col>
            <b-col class="text-end fw-bold text-secondary"
              ><span>{{
                customer.regency && customer.regency.name
                  ? customer.regency.name
                  : "-"
              }}</span></b-col
            >
          </b-row>
          <b-row class="mt-1">
            <b-col><span class="textSupply fw-bold">Kecamatan</span></b-col>
            <b-col class="text-end fw-bold text-secondary"
              ><span>{{
                customer.district && customer.district.name
                  ? customer.district.name
                  : "-"
              }}</span></b-col
            >
          </b-row>
          <b-row class="mt-1">
            <b-col><span class="textSupply fw-bold">Desa</span></b-col>
            <b-col class="text-end fw-bold text-secondary"
              ><span>{{
                customer.village && customer.village.name
                  ? customer.village.name
                  : "-"
              }}</span></b-col
            >
          </b-row>
        </b-card>
        <b-card
          v-if="swipeLeft === customer.id && checkRoles"
          class="rounded-3"
          style="width:150px !important;"
          :class="`${swipeLeft === customer.id ? 'buttonAction' : ''}`"
        >
          <div
            class="d-flex flex-column justify-content-center align-items-center gap-3 h-100"
          >
            <div>
              <el-button
                @click="handleRouteEdit(customer.id)"
                type="warning"
                icon="el-icon-edit"
                circle
                class="btn-custom"
              />
            </div>
            <div>
              <el-button
                @click="handleDelete(customer.id)"
                type="danger"
                icon="el-icon-delete"
                circle
                class="btn-custom"
              />
            </div>
          </div>
        </b-card>
      </div>

      <div
        class="d-flex gap-2"
        style="width: auto;overflow-x: scroll;height: fit-content;"
        :class="`${swipeLeft === user.id ? 'swipeWrap' : ''}`"
        v-if="cardUser"
        v-for="user in dataApi"
        :key="user.id"
      >
        <b-card
          v-touch:swipe.left="() => handleSwipeLeft(user.id)"
          v-touch:swipe.right="() => handleSwipeRight(user.id)"
          @click="handleSwipeRight(user.id)"
          class="w-100 rounded-3"
          :class="`${swipeLeft === user.id ? 'swipe' : ''}`"
        >
          <b-row @click="handleRouteDetail(user.id)">
            <b-col
              class="col-3 d-flex justify-content-start"
              style="height: 55px !important;"
            >
              <span
                class="h-100 d-flex justify-content-center align-items-center fs-1 rounded-1"
                style="background-color: #c1c9d7;width: 60px;"
              >
                <i class="bx bxs-user"></i>
              </span>
            </b-col>
            <b-col class="d-flex flex-column">
              <span class="fw-bold text-dark" style="font-size: 15px;">{{
                user.name
              }}</span>
              <span class="fw-bold text-secondary">
                {{ user.gender }}
              </span>
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col><span class="textSupply fw-bold">Role</span></b-col>
            <b-col class="text-end fw-bold text-secondary"
              ><span>{{ user.role }}</span></b-col
            >
          </b-row>
          <b-row class="mt-1">
            <b-col><span class="textSupply fw-bold">Email</span></b-col>
            <b-col class="text-end fw-bold text-secondary"
              ><span>{{ user.email }}</span></b-col
            >
          </b-row>
          <b-row class="mt-1">
            <b-col><span class="textSupply fw-bold">No. Handphone</span></b-col>
            <b-col class="text-end fw-bold text-secondary"
              ><span>{{ user.phone_number }}</span></b-col
            >
          </b-row>
        </b-card>
        <b-card
          v-if="swipeLeft === user.id && checkRoles"
          class="rounded-3"
          style="width: 200px !important;"
          :class="`${swipeLeft === user.id ? 'buttonAction' : ''}`"
        >
          <div
            class="d-flex flex-column justify-content-center align-items-center gap-2 h-100"
          >
            <div>
              <el-button
                @click="handleRouteEdit(user.id)"
                type="warning"
                icon="el-icon-edit"
                circle
                class="btn-custom"
              />
            </div>
            <div>
              <el-button
                @click="handleDelete(user.id)"
                type="danger"
                icon="el-icon-delete"
                circle
                class="btn-custom"
              />
            </div>
          </div>
        </b-card>
      </div>

      <b-card
        v-if="cardDefault"
        v-for="(item, index) in dataApi"
        :key="item.id"
        class="rounded-lg mt-3"
      >
        <span>{{ item }}</span>
      </b-card>

      <skeletonLoading
        v-if="busy"
        v-for="load in loadCard"
        :key="load"
        class="mb-3 mt-2"
        :width="skeletonWidth"
        :height="skeletonHeight"
        :borderRadius="skeletonRadius"
      />
      <b-card v-if="dataApi.length == 0" class="rounded-3 text-center"
        >Data Tidak Ada / Tidak di Temukan</b-card
      >
    </div>

    <!-- modal -->
    <transition name="modal-fade">
      <div
        class="modal d-flex flex-column align-items-center"
        v-if="isModalVisible"
      >
        <div class="w-100 h-100" @click="hideModal"></div>
        <div class="modal-content">
          <b-row>
            <b-col
              class="col-md-12 d-flex align-items-center justify-content-center fs-2 font text-black"
            >
              <div class="icon-line"></div>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col sm="6" class="my-2" v-if="withFilterText">
              <el-input
                v-model="filterText"
                :placeholder="titleFilter"
                clearable
              >
              </el-input>
            </b-col>
            <b-col sm="6" class="my-2" v-if="withFilterCreated">
              <el-input
                v-model="filterCreated"
                placeholder="Dibuat Oleh"
                clearable
              >
              </el-input>
            </b-col>
            <b-col sm="6" class="my-2" v-if="withFilterColor">
              <el-input
                v-model="filterColor"
                placeholder="Filter Warna"
                clearable
              >
              </el-input>
            </b-col>
            <b-col sm="6" class="my-2" v-if="withFilterOrder">
              <el-input
                v-model="filterOrder"
                placeholder="Filter No. Order"
                clearable
              >
              </el-input>
            </b-col>
            <b-col sm="6" class="my-2" v-if="withFilterDate">
              <date-picker
                placeholder="Pilih Tanggal"
                :value="filterDate"
                @input="updateFilterDate"
                type="format"
                v-model="filterDate"
                icon-calendar=""
                range
                clearable
              ></date-picker>
            </b-col>
            <b-col class="my-2" v-if="withFilterSize">
              <el-input
                v-model="filterSize"
                placeholder="Filter Ukuran"
                clearable
              ></el-input>
            </b-col>
            <b-col class="my-2" v-if="withFilterStatus">
              <el-select v-model="filterStatus" placeholder="Status" clearable>
                <el-option label="Lunas" value="Lunas"></el-option>
                <el-option label="Pending" value="Pending"></el-option>
                <el-option label="Batal" value="Batal"></el-option>
              </el-select>
            </b-col>
            <b-col class="my-2" v-if="withFilterCategory">
              <el-select
                class="w-100"
                v-model="filterCategory"
                placeholder="Kategori Pelanggan"
                clearable
              >
                <el-option
                  v-for="category in selectOptions"
                  :label="capitalizeFirstLetter(category.value)"
                  :key="category.id"
                  :value="category.id"
                ></el-option>
              </el-select>
            </b-col>
            <b-col sm="3" class="my-2" v-if="withFilterRole">
              <el-select
                class="w-100"
                v-model="filterRole"
                placeholder="Pilih peran"
                clearable
              >
                <el-option
                  :label="capitalizeFirstLetter(option.value)"
                  v-for="(option, index) in selectOptions"
                  :key="index"
                  :value="option.value"
                  v-if="option.value != 'owner'"
                ></el-option>
              </el-select>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col class="my-2">
              <el-button type="primary" @click="applyFilter">Filter</el-button>
              <el-button type="primary" plain @click="clearFilter"
                >Clear</el-button
              >
            </b-col>
          </b-row>
        </div>
      </div>
    </transition>
  </Layout>
</template>

<style scoped>
.expanded {
  transform: translateX(-200px); /* Ubah jarak bergeser sesuai kebutuhan */
}

.modal {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  background-color: rgba(0, 0, 0, 0.5);
  overflow: hidden;
}

.modal-content {
  padding: 20px;
  background-color: white;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  box-shadow: 0px -4px 10px -3px rgba(0, 0, 0, 0.1);
  transition: all 0.5s ease;
}

/* Efek transisi */

.modal-fade-enter-active,
.modal-fade-leave-active .modal-content {
  transition: all 0.3s ease;
}
.modal-fade-enter > .modal-content {
  transform: translateY(100%);
  opacity: 0;
}
.modal-fade-leave-to > .modal-content {
  transform: translateY(-100%);
  opacity: 0;
}

body.modal-open {
  overflow: hidden;
}

.addData {
  width: 60px;
  height: 60px;
  position: fixed;
  z-index: 999;
  bottom: 5vh;
  right: 5vw;
  border-radius: 50%;
}

.filter {
  gap: 5px;
  width: 80px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #c1c9d7;
  border-radius: 10px;
  padding: 2px 10px;
  font-weight: bold;
  color: #a3abb9;
}

.swipeWrap {
  width: 500px !important;
}

@media only screen and (min-width: 600px) {
  .swipeWrap {
    width: 100% !important;
  }

  .buttonAction {
    padding: 10px;
    width: 800px !important;
    position: relative;
    left: -150px;
  }
}

.swipe {
  transition: all 1s ease;
  position: relative;
  width: 700px !important;
  left: -150px;
}

.buttonAction {
  padding: 10px;
  width: 500px !important;
  position: relative;
  left: -150px;
}

.textSupply {
  color: #a3abb9;
}

.filter i {
  color: #000;
}

.icon-line {
  width: 60px;
  height: 5px;
  background-color: #d6dfeb;
  border-radius: 3px;
}

.badge-custom {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 15px;
}
</style>
